import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse, CancelToken } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { MarketplaceMembersFilteringParams } from '@modules/marketplace-members/pages/marketplace-members-index';
import { marketplaceMembersActions } from '@redux/reducers/marketplace-members/marketplaceMembersReducer';
import { marketplaceMembersSelectors } from '@redux/selectors/marketplace-members/marketplaceMembers';
import { MarketplaceMembersState } from '@redux/types/marketplace-members/marketplaceMembersTypes';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

const getData = (params: MarketplaceMembersFilteringParams, cancelToken: CancelToken) => {
  return $http.get<AxiosResponse<MarketplaceMembersFilteringParams>>(`/society/members${getQueriesAsSearch(params)}`, {
    cancelToken,
  });
};

export function* getSocietyDashboardSaga(action: PayloadAction<MarketplaceMembersFilteringParams>) {
  const source = getCancelRequestSource();

  try {
    yield put(marketplaceMembersActions.setLoading(true));

    const { data } = yield call(() => getData(action.payload, source.token));

    yield put(marketplaceMembersActions.getDataSuccess(data));
  } catch (e) {
    yield put(marketplaceMembersActions.getDataFailure(getDefaultError(e?.response?.data?.message)));
  } finally {
    const { loading }: MarketplaceMembersState = yield select(marketplaceMembersSelectors.getState);

    if (loading) {
      source.cancel();
    }
  }
}

export default function* marketplaceMembersSaga() {
  yield all([takeLatest(marketplaceMembersActions.getDataRequest.type, getSocietyDashboardSaga)]);
}
